.dialog-content {
  max-height: calc(100vh - 75px);
  padding-right: 32px !important;
  position: relative;
}

.dialog-container {
  max-width: 60%;
}

@media screen and (max-width: 992px) {
  .dialog-container {
    max-width: 100%;
  }
}

.overflow-auto {
  overflow: auto;
}

.markdown-content ol,
.markdown-content ul {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
  font-family: ABBVoice-light;
}

.markdown-content ul {
  list-style-type: disc;
}
.markdown-content ol {
  list-style-type: decimal;
}
