@use "~ag-grid-community/styles" as ag;

@import "./commonUXVariables.scss";
@include ag.grid-styles(
  (
    theme: balham,
    row-hover-color: $color-grey-10,
    odd-row-background-color: $color-white-secondary,
    background-color: $color-white-primary,
    font-family: (
      $font-abb-regular
    ),
    font-size: $font-size-s
  )
);

.ag-theme-balham {
  --ag-selected-row-background-color: #{$color-blue-20};

  .ag-cell-focus {
    border-color: $color-grey-60 !important;
  }

  .ag-tooltip-custom {
    border: 1px solid transparent;
  }

  .ag-root-wrapper {
    border: 0;
    overflow: visible;
  }

  .ag-header {
    background-color: $color-white-primary;
    font-size: $font-size-xs;
    border: 0;
  }

  .ag-header-cell {
    color: $color-grey-50;
    text-transform: uppercase;
    font-weight: normal;
    border-bottom: 1px solid $color-grey-90;
  }

  .ag-row {
    border-color: $color-grey-30;
    height: auto;
    .ag-cell {
      line-height: 35px;
      font-size: $font-size-s;
      color: $color-grey-90;
    }
  }

  // Unable to set SCSS variable directly to CSS variable: https://stackoverflow.com/a/50204145
  .ag-row-selected.order-status-partial-delivery-row {
    --ag-selected-row-background-color: #{$color-yellow-20};
    --ag-row-hover-color: #{$color-yellow-20};
    &::before {
      background-image: none;
    }
  }

  .ag-row-selected {
    background-color: $color-grey-20 !important;
    &.grid-white-background {
      background-color: $color-grey-20 !important;
    }
  }

  .ag-checkbox-input-wrapper.ag-checked::after {
    color: $color-black-primary !important;
  }
}

.grid-inactive-row {
  div {
    color: $color-grey-40 !important;
  }
}

.grid-inactive-company-row {
  div {
    color: $color-status-red !important;
  }
}

.grid-active-row {
  div {
    font-weight: bold !important;
  }
}

.grid-react-container-flex {
  .ag-cell-wrapper {
    width: 100%;
    height: 100%;
    .ag-cell-value {
      width: 100%;
      height: 100%;
    }
  }
  .ag-react-container {
    width: 100%;
    height: 100%;
  }
}

.grid-white-background {
  background-color: $color-white-primary !important;
  &:hover {
    background-color: undefined;
  }
}

.grid-grey-background {
  background-color: $color-white-tertiary !important;
  &:hover {
    background-color: undefined;
  }
}

.grid-red-background {
  background-color: $color-red-10 !important;
}

.grid-header-center > div > .ag-header-cell-label {
  justify-content: center !important;
}

.grid-header-center-with-menu > div > .ag-header-cell-label {
  justify-content: center !important;
  padding-left: $m;
}

.grid-checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
  div {
    .ag-selection-checkbox {
      margin-right: 0 !important;
    }
  }
}

.custom-tooltip {
  pointer-events: none;
  position: absolute;
  z-index: $tooltip-z-index;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(235, 235, 235);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 6px 12px;
}

.custom-tooltip > div {
  padding: $xs;
  max-width: 500px;
  max-height: 700px;
  width: max-content;
}

.custom-tooltip.ag-tooltip-hiding {
  opacity: 0;
}

.custom-tooltip p {
  margin: 0;
  white-space: pre-line;
}

.start-from-top {
  align-items: flex-start !important;
  .ag-cell-wrapper {
    align-items: flex-start;
    display: flex;
    flex: auto;
  }
}

.grid-row-padding {
  .ag-cell {
    padding-top: 12px;
    padding-bottom: 12px;
  }
}

.grid-light-border {
  border-color: $color-grey-10 !important;
  &:hover {
    background-color: $color-grey-10 !important;
  }
}

.state-light-green:after {
  content: "";
  position: absolute;
  height: 30px;
  width: 4px;
  align-self: center;
  top: 3px;
  box-shadow: inset 5px 0px 0px 0px $color-green-20;
}

.state-green:after {
  content: "";
  position: absolute;
  height: 30px;
  width: 4px;
  align-self: center;
  box-shadow: inset 5px 0px 0px 0px $color-status-green;
  top: 3px;
}

.state-yellow:after {
  content: "";
  position: absolute;
  height: 30px;
  width: 4px;
  align-self: center;
  top: 3px;
  box-shadow: inset 5px 0px 0px 0px $color-status-yellow;
}

.state-red:after {
  content: "";
  position: absolute;
  height: 30px;
  width: 4px;
  align-self: center;
  top: 3px;
  box-shadow: inset 5px 0px 0px 0px $color-status-red;
}

.ag-popup-child,
.popup-content {
  z-index: $tooltip-z-index !important;
}

.grid-without-header {
  .ag-header-cell {
    border-bottom: 0 !important;
  }
}
